import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio;`}</li>
      <li parentName="ul">{`An previous onsite campaign with one version of the content. `}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Note: The campaign selected to use in the A/B test will become a variation of the test, the control one. `}</p>
    </blockquote>
    <h2>{`Overview`}</h2>
    <p><strong parentName="p">{`A/B testing`}</strong>{`  is part of one campaign and measures the impact on conversion by knowing which content converted. For that, we should define what is the conversion goal (what means conversion for the campaign).`}</p>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add campaign`}</h3>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{` (`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACe0lEQVQoz32QyWsTARTG5z8QVFwuKiq2dBFJBdGjqOCl3hREcVf8B8STUMW9HkTQghdXaOtS28nMtLbBYsFaWxubGmNrYpqETDJJprOkmWZp8pOmKnjxgx+893jv8fEJdTt2s+HiS7bdGKDpZh9bLktsbhGpvSJSd1Wk4Zqbhusi9ddFXLclXK0S2+9INLW62XVXYe+Dt+xr62fPPTc773QhrGvcxS3ZSyht8zVuEEhafP+DZhFM24xGMyj+GAFVxx/TCGqz9AbijM5oTMZ1ApqJTzU40T6EsGqLizdjQf6nChDVbaiUoVyASpGE7WBlc5QKhSrlUpEL0keENTVNdI5MVQ8LpQUWypV/WNRcvkh48SFQWhoR1i0iagJd19ENA9OyuCAPI6yucfHy03R1qbiwQPn3k6qzylLtFEtEZrOUi3mcuSyVYp64mcU0DBzLpJjLUcnPc2lgBGFtrYuWnhEmEyYjkTRedRbJH+V9UGU0msKr6nh+xOmbiqJZNtNahphhI09FaPs4waOxSR6P+3ni9XO2x4OwbH094pcQ0Xia+52DBEIqUTOH5cxjW1aVfKnE0E+N3m9J/vgfjiXY/7yb5nY3zR0iBzpEDr6SEFZu2srToUmeicMktRQPXwwyEUlhOw4z4TChUAgzm8UXzzAejP/NdTyhcei1zNFuhWM9CsfERWSE5Rsb6Rqbpve9j/4PX3n1doxwxmK+UEBPpchoGk6+QEw3sNPa33x9qTSHuyWOSwonFYVTvQqn+xSEFRsbafN4+RaM0OH5TCxt4tcMfDMqM2qSTHaORDbHeHSxT6BaNrM5h3fhCEd63JyUZc70yZzrlznvkfkF/n6e7vxCZiUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/e93cc/ec-menu.webp 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/b0544/ec-menu.webp 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/68fc1/ec-menu.webp 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/a2303/ec-menu.webp 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/4293a/ec-menu.webp 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/81194/ec-menu.webp 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/eed55/ec-menu.png 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/7491f/ec-menu.png 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/d2cc9/ec-menu.png 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/00711/ec-menu.png 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png",
              "alt": "ec-menu",
              "title": "ec-menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Click `}<em parentName="p">{`Campaigns`}</em>{` on the menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1142px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "140.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEkklEQVRIx5XU6VNTZxTH8audVm3dkF2BmJBAglaxiw61HWfU1mlRrI4O1g3BIkIIEdkklkIJBWuxikAFlTUs2ZO7JAS3mfqmf9a3c28wgnUUXpx5Xj2fOef5nXuFzU0S66+LrG8Q+eS6yMYGkaQmiaRGkc2OJ2T+1Ik5z4xl916Mpc2YLvdgKndiKu/CcMmJsfx15V92Iqy2BTG0z6Nrm8PQHiO5RUGo9iNU+xDsEYQjjVirrlK49xs2fHuFz6pvUVDxG3mXOiisdPJ5VRe7KjvZU9WFpaIjDqbeUEhpUbRzQ4OEYA2wyupHqI/yUXELJT8cxbzrS1KPWsm/3MX2823knG8jr7wDY1m7dporOtBf/BVBu1wbYHVtAEFFavwaKNT4EK4prDl6g6SNSaRs1ZNWYsNU4eSr2h6KrD0U1S6UtZui2m7223oQVtcGyWxVyPklSkqzjHDVF0dVsE5h/Yk2vjt0mH1fHyS5uIbu4SmUcBCf34/H7SYQ8CPLMrIkIUmSCgZIbpZJbpLY3CiR1iJrwWigPQ5a8szkWgrZ+qON/kkvc7JEKBwmFAoRDoc1SFoMblnANjWIpN9Q2NQoLoSisLakFZPewDa9WQP7JjzEIjJhUUwgoigmSgPTWhQyWhUN08at9ic6XFviQJ+jI8tgIeuEjfuTHmJKHHyFvEKXjKzu3ge2oBZQIhS7wsfHb5Kfa0Rn2kH2yaXg4lFfj6yuTYvClib5dcKJlCOsK3Gwq6AAff6nGqi94btAtatXKWtvt3ht7IoGGnTbyc61oDtVR/+k592gOqKa6hp7aGEXl4687riDPEN8ZA10LaPDrJsRPqwL/n9kNZRjrRqYYyxg++k67Q2jikwoLC5JN5Fy/PIi6M0OSxwUqHto3q2BQzM+ns1FiMiyBr9ZglAb5K2lfobXoqw51oouK5tt+nx0p204BsZxeXyMzHgYnfUuqbFZ70KHby1/IuWdZgumgkINPNB4mzJnHxc6+7jojNeFzntcdN6jrKvvfR1GtMXemp5JRo6J3NI67ozNIIcCeL1evB4vPq+XWFTh6fyc9j0vC8xIzSA924jprJ3BKTVliWAoxHwsij8Q5NbdIe70P4qnvBwwMy3eoQoOTHmIyBKxuSjtt/rR7z+L5WAZeQcuUHCobPlgeraJvLN2+ifdvHgyx1+DI6QUnqSpvVf76/h8Aa40OFcG5qugy80/z+c5fMbOqcpWomKAkcePGR8bxeueWcHIKnjOzoDLw4v5OfZ8/zMO512iioTL5WJmehqfz79yUO3w5fN5jpyr51hZI/++fEEoGEQWw1rSKwLNCx0+i0UZfDhOyp6T2rsFAkFm3T5KqxzvX+zFKavg39Pxv83TJ/P0Djxmx+FycorOkLWvlC+KK98B1rwZipGdZfV0PxhnaGiE4eEhXBNjjI2OMPRogtGJGWLRyMr20HzeTufDKUYnZxl4MMTg8EOmZt2IsoQoy/iCIbVDP8JVN0LVLELVTKJWXZlGsAZZV9xERmo6Gdm5GE5XUdzyO829/TT0DtB4Z4Drf97n2h/3qFfrdh//AZ3y+NrIMEgYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/e93cc/ec-adCenter-campaign.webp 300w", "/static/9d58220d59f992bf224028c42f529ca9/b0544/ec-adCenter-campaign.webp 600w", "/static/9d58220d59f992bf224028c42f529ca9/7c99d/ec-adCenter-campaign.webp 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/eed55/ec-adCenter-campaign.png 300w", "/static/9d58220d59f992bf224028c42f529ca9/7491f/ec-adCenter-campaign.png 600w", "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
              "alt": "ec-adCenter-campaign",
              "title": "ec-adCenter-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. Click `}<em parentName="p">{`Add`}</em>{` to open a new Campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACS0lEQVQoz4XR/UtTURzH8fsfRCgVKj1rkoIapgkRkihYQRAEQQRBIBb1oz2g9OAPItkDVNSydG5T0PSuvE7cZv1gqTnnSgnWNTcL+ik15ja33T3cvcOraEHRB158z4Fzzg+fI+SUlLO5pof0un7SayU21IhsvCySelVk0zWRLbUiaXUiGddFtt4ws+2WmR31ZnbWm8lu6KWgqY/Ce/3k3+6luLEbIW1vMdWmEUyOWXRDMs3DX2h570E/5qVtzIvB4cUw7sW4xoPJ6aFjwkv7+AydLi9dH79invrGJXEUITWzgG7HDCtR+W8SsbVlXAkTCYchmdT2XZNehIzcA4jO1QeTidWjyX9LxLWpxhPaTESjxBRFu9Uz5UHI2l9KVfs7dCMzPBxy82RYRjci83RU5tl7medjMi0OmdbxafTOZTIG17TG6JIxfZimzfUZvdPNFesowvbcIrJudpPdIJHT2Etek8S+uxKF9yWKHkiUPJI4+FjikE6itLmPshYL5a0WKvR9VBr7OWKyaI62rxBSM/MxjLgJRRTm/Ev4QhGNPxIloMTWBKMx/BGFhcASi+EIfiXKD98i8/4AixGFeELFOOlGSNmVh3litUM18Vtff48aj4OqoiZUrb9QSCEaXfkoq2cWIWV3Hq1vPzHv8/N9wcdcIPSH+eC6hWVL636Gwpq5YJBgKESX242QtqeA4juvOKwbpEw3QEXzAJUtVo61WTlutHGiw8bJThunXtg4Ldo489LOWcnOOYudqgE71TY7FwbtXHxj5/xrG78A0wOGSy6/ESYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/e93cc/ec-add-campaign.webp 300w", "/static/9e640e031871779b1f6dd619d333233f/b0544/ec-add-campaign.webp 600w", "/static/9e640e031871779b1f6dd619d333233f/68fc1/ec-add-campaign.webp 1200w", "/static/9e640e031871779b1f6dd619d333233f/a2303/ec-add-campaign.webp 1800w", "/static/9e640e031871779b1f6dd619d333233f/4293a/ec-add-campaign.webp 2400w", "/static/9e640e031871779b1f6dd619d333233f/cb4c3/ec-add-campaign.webp 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/eed55/ec-add-campaign.png 300w", "/static/9e640e031871779b1f6dd619d333233f/7491f/ec-add-campaign.png 600w", "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png 1200w", "/static/9e640e031871779b1f6dd619d333233f/d2cc9/ec-add-campaign.png 1800w", "/static/9e640e031871779b1f6dd619d333233f/00711/ec-add-campaign.png 2400w", "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png",
              "alt": "ec-add-campaign",
              "title": "ec-add-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
4. Change `}<em parentName="p">{`Type`}</em>{` to `}<em parentName="p">{`A/B test`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a996caf4c45e19970517f350c36ee1bc/3e1c8/ec-new-campaign-ab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABL0lEQVQ4y52TS07FMAxFu1bm7IoRW2ALDBgiBkz4CFCbJv3n14tuRJ5MScuDSFZS2b49dpzq+eUVpuugtYFSLRqlYJ2Dcx4u7WeY9ylnsRYVRZZlwTRNGMcRwzDgX2tdEWNE1bZtwbei6zp47xFCODTGZuM6CUoHre/7RE0/K6C4LJPfWutEJfNPgnRIwUzAZO70b42i27wfhFwUMcZgnue075XLtpxFyHNOstZ++5bGHx8SZmH2RhIyhhMghXkuEjaqTJiTSUHK3MtfCTnIkpC32zRNoiIh53Lbhj8TSgE5i3u3nGK/8ou3zH4ppdIc1nWdSmZf8yvKcaVVMXAvaPsKxCuDDxEuRCw+4rEe8PDRw8wOFftVKvnI4rpCTxZvZsaTGnF5fYeLq1vc3L/jE12XSs5oRWZ5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a996caf4c45e19970517f350c36ee1bc/e93cc/ec-new-campaign-ab.webp 300w", "/static/a996caf4c45e19970517f350c36ee1bc/b0544/ec-new-campaign-ab.webp 600w", "/static/a996caf4c45e19970517f350c36ee1bc/68fc1/ec-new-campaign-ab.webp 1200w", "/static/a996caf4c45e19970517f350c36ee1bc/077a8/ec-new-campaign-ab.webp 1410w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a996caf4c45e19970517f350c36ee1bc/eed55/ec-new-campaign-ab.png 300w", "/static/a996caf4c45e19970517f350c36ee1bc/7491f/ec-new-campaign-ab.png 600w", "/static/a996caf4c45e19970517f350c36ee1bc/8537d/ec-new-campaign-ab.png 1200w", "/static/a996caf4c45e19970517f350c36ee1bc/3e1c8/ec-new-campaign-ab.png 1410w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a996caf4c45e19970517f350c36ee1bc/8537d/ec-new-campaign-ab.png",
              "alt": "ec-new-campaign-ab",
              "title": "ec-new-campaign-ab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
4. Fill in the name and control campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b30be5b60a53f9fcaeb3c214f1717f01/9a4c4/ec-campaign-ab-filled-in.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABeUlEQVQ4y52TwU6EMBRF+XS/wQ/wB1y4ce/GxJULF25MTCZj4mQcChTaUmjLNbfYSRXGMTa5efTBa0/vK8X+4wBRVRBVjVKI+CzbdpZs0cg5HnO/qJEShdYaTVOj6zoIISClxH9HCAGF937xwocA5xy63qHRFlJbKOthxgA9zDKMY4APEzBNmKYp1hYs5JiyJHOkPjQK290Bu7KBNCM66+MmFDcopcHg/Lf6I2FKJPV9D2t7KKUQvI/HiTEpBBij4b07vyBzpKTGcYzzNVlrY8xrFwty8MO2bWGMid1jnkT5YpyzoT8tWyzIeaJKhNxgjfQsYWpIXc/XiD7yGnFO2pzyJGGe4EckSf4lrR2ZGoZhSeiObZ8vJr0jESMJSRE7/KWckDdghXDZlLzL9CmPiZZKNc6HqBBOdJn+0Tv6VlVVtIHUJM79Xhurv95fRj96KOuibp7ecfWwwf1riYJHWTOcxzs1559Sa4s3obCXBpd3L7i4fcb14xaflomXe79/YZ0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b30be5b60a53f9fcaeb3c214f1717f01/e93cc/ec-campaign-ab-filled-in.webp 300w", "/static/b30be5b60a53f9fcaeb3c214f1717f01/b0544/ec-campaign-ab-filled-in.webp 600w", "/static/b30be5b60a53f9fcaeb3c214f1717f01/68fc1/ec-campaign-ab-filled-in.webp 1200w", "/static/b30be5b60a53f9fcaeb3c214f1717f01/d6f56/ec-campaign-ab-filled-in.webp 1404w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b30be5b60a53f9fcaeb3c214f1717f01/eed55/ec-campaign-ab-filled-in.png 300w", "/static/b30be5b60a53f9fcaeb3c214f1717f01/7491f/ec-campaign-ab-filled-in.png 600w", "/static/b30be5b60a53f9fcaeb3c214f1717f01/8537d/ec-campaign-ab-filled-in.png 1200w", "/static/b30be5b60a53f9fcaeb3c214f1717f01/9a4c4/ec-campaign-ab-filled-in.png 1404w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b30be5b60a53f9fcaeb3c214f1717f01/8537d/ec-campaign-ab-filled-in.png",
              "alt": "ec-campaign-ab-filled-in",
              "title": "ec-campaign-ab-filled-in",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Type`}</em>{`: A/B test.`}</li>
      <li parentName="ul"><em parentName="li">{`Channel`}</em>{`: Select `}<em parentName="li">{`Default`}</em>{` or create a new one to add.`}</li>
      <li parentName="ul"><em parentName="li">{`A/B Test type`}</em>{`: `}<em parentName="li">{`Onsite campaign`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Control campaign`}</em>{`: It is required.Select the campaign to use as control.`}</li>
      <li parentName="ul"><em parentName="li">{`Disable inline campaigns in control campaign`}</em>{`: `}<em parentName="li">{`Yes`}</em>{` or `}<em parentName="li">{`No`}</em>{` field.`}</li>
      <li parentName="ul"><em parentName="li">{`Start date`}</em>{`: datepicker for defing when is going to start the A/B testing.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`On the left bottom corner of form `}<em parentName="li">{`Add campaign`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
    </ol>
    <h2>{`Result`}</h2>
    <p>{`After accepting, `}<em parentName="p">{`Edit Onsite A/B TEST`}</em>{` is open.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/40555/ec-edit-onsite-ab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABiklEQVQoz6XNTWsTURTG8fsZFESkVnzBveuC9Bt1I4qS4vum7uoXKNS31nSmSlC3LgStSWO1pGkluTPEGptMm3YmdebOzL1/ySTUSEWQHvhx4HB4HnE6V2AkV2B0ssCxqxbHr1mcuG5x8obFqZzFyKTF6E2LM7dszt62OXfH5vxdmwv3bC7eX+TSgwKXH75hfPoVY1N5xMR8iXzJYfZ9jdkPdR4tSR5/lDwpSp6WJM96liVzPWXJ/MDzTw5zy3UWyjWm364xsVDkir2EeL26yVHHDxMqzYBK00csrjSyo0o1qTakur/3goBd3ydUijhJidPDkowGTJaxH8eIl58HgYlGZ4EGYwxKqUyappl/jRnsvUgh8mWXIExoBxEqSQnjXrv+/WzMX2mtD0RRhIpCdrr7iBcrTtbxM1J/tpoBDhsu0sbQ7XbZ7XTw/ABhFTcIvC3czSZbrRad7W12/oPneXz70cZrtfje9hAz7yq4jQZfvkoqNcla3TlQHSb71oc5fVUpqdbqrLouvwC+k4m2lgnOywAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/32dd2dd404f3943c7f0c5404f3b2c7ad/e93cc/ec-edit-onsite-ab.webp 300w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/b0544/ec-edit-onsite-ab.webp 600w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/68fc1/ec-edit-onsite-ab.webp 1200w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/a2303/ec-edit-onsite-ab.webp 1800w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/4293a/ec-edit-onsite-ab.webp 2400w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/46158/ec-edit-onsite-ab.webp 2840w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/32dd2dd404f3943c7f0c5404f3b2c7ad/eed55/ec-edit-onsite-ab.png 300w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/7491f/ec-edit-onsite-ab.png 600w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/8537d/ec-edit-onsite-ab.png 1200w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/d2cc9/ec-edit-onsite-ab.png 1800w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/00711/ec-edit-onsite-ab.png 2400w", "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/40555/ec-edit-onsite-ab.png 2840w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/32dd2dd404f3943c7f0c5404f3b2c7ad/8537d/ec-edit-onsite-ab.png",
              "alt": "ec-edit-onsite-ab",
              "title": "ec-edit-onsite-ab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3>{`2. Edit onsite A/B testing`}</h3>
    <ol>
      <li parentName="ol">{`Click `}<em parentName="li">{`Options`}</em>{` (`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "36px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/58e141f2a23b7f339e87d4aea23a23ed/07796/ec-options.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "105.55555555555556%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB90lEQVQ4y62V2atxURjG/f8i4zGPkeLCcEFE4ULCDXekyHAhN+aZ5/S8tXey7eN8X2fVarXW2uv3Pu/gZTgej/jLadC7OBwO6rrf72V9PvsIfH2gzPP5jNvthsvlovudLvB5Jeh0OmE+n6PT6WAwGIhSnvFOD2p4dZNK1uu1gDhKpRKMRiOi0ajAVqsVFosFrtfrW9c1CgnLZrPw+XxoNBqIx+Nwu92yr9frSCaTCAaDmE6nqlKNQuWQ6uiaw+EQiN1ux9fXl+y5cu9yuWC1WlGtVuX753hrFNIiR7FYFIjH40EgEBDFsVhMYDQUiUSw2+0kWboKCaMbdItuOp1OhEIhjMdjechQZDIZMeT3+1GpVNDv9zXJFCCzd7/fUavVYDKZRBkfMiGPxwObzUbuGQ6bzQav1wuLxYJEIiFK3wKpot1ui1sEUmE6nZY4cXKwfBhHAnnPUPCtBqhsaI1lUSgU1MQw+AxFt9uVEDA54XAYs9lMlH9MCmOVSqXUBChgKiKM6llCw+FQrcUfy2Y0GqklwuDzMSFKxmnIbDZL8j6WDSet9no95PN5LJdLiSPrjuDJZIJWq4VyuazbKAzvOozyO+ZoNpvici6XUw0q9++gb5vD81QStd1uNXe/ag6vUO6pRlH06/b1U4N9B/rnjv3nfwH/C/wGadoAFkbEcugAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/58e141f2a23b7f339e87d4aea23a23ed/9da8f/ec-options.webp 36w"],
                "sizes": "(max-width: 36px) 100vw, 36px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/58e141f2a23b7f339e87d4aea23a23ed/07796/ec-options.png 36w"],
                "sizes": "(max-width: 36px) 100vw, 36px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/58e141f2a23b7f339e87d4aea23a23ed/07796/ec-options.png",
                "alt": "icon-options",
                "title": "icon-options",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/90e296d38c23c1f41c6fa302405c4ba5/40555/ec-edit-variation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABr0lEQVQoz6XQX2vTUBjH8bwGEURkTvyDr8Abb/T9eKuiuKnoEEEQBH0NQ2cTnd1UEB1j2jndOtPWNjG4dbqtTZP9Se3aJOfkKyepijC92YEPh/Nwzu95ONrAUJ7Dw3kGh/Psu2iw/5LOgcs6B6/oHBrSGbiqM3jN4Mh1g6M3DI7dNDg+YnBixODkraecujvBmfsvOXvvGafv5NAu5BZ4UqwzOveV0Y/LPJzPPFpYZqxY5/FiJvepjt5nmJnc4hLj5hK3X1U4b8xzbqyA9rq2zl6XG/So+12mnCbaZHk1LUZCImQmlpKtIGA7aNMNQ6JYEAtJJMRfVE2BJM2ouRtok5Xv6aEbCWSSIGRCkiSEKiiKEEIgpfzvhDLLo9LcQBs3vxF0Y7wfPcJYpMFZ12yp8N2oJopq2Ol0SKKQ8noL7Xl5JR25F4Z/LqcP+pLdiX5gLET6PTvtgNJaE22i6NBuNVhZXaPlumz5Hpu+93v/J89j2/dpNFw8fxOx06HacNEeTJl8qDq8MS2mSxYzZZuZis1b5bPNO6VqU1BqNrO/WDbv7S8UahZzlkXJcXhRrfET4RyHIsknmDgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/90e296d38c23c1f41c6fa302405c4ba5/e93cc/ec-edit-variation.webp 300w", "/static/90e296d38c23c1f41c6fa302405c4ba5/b0544/ec-edit-variation.webp 600w", "/static/90e296d38c23c1f41c6fa302405c4ba5/68fc1/ec-edit-variation.webp 1200w", "/static/90e296d38c23c1f41c6fa302405c4ba5/a2303/ec-edit-variation.webp 1800w", "/static/90e296d38c23c1f41c6fa302405c4ba5/4293a/ec-edit-variation.webp 2400w", "/static/90e296d38c23c1f41c6fa302405c4ba5/46158/ec-edit-variation.webp 2840w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/90e296d38c23c1f41c6fa302405c4ba5/eed55/ec-edit-variation.png 300w", "/static/90e296d38c23c1f41c6fa302405c4ba5/7491f/ec-edit-variation.png 600w", "/static/90e296d38c23c1f41c6fa302405c4ba5/8537d/ec-edit-variation.png 1200w", "/static/90e296d38c23c1f41c6fa302405c4ba5/d2cc9/ec-edit-variation.png 1800w", "/static/90e296d38c23c1f41c6fa302405c4ba5/00711/ec-edit-variation.png 2400w", "/static/90e296d38c23c1f41c6fa302405c4ba5/40555/ec-edit-variation.png 2840w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/90e296d38c23c1f41c6fa302405c4ba5/8537d/ec-edit-variation.png",
              "alt": "ec-edit-variation",
              "title": "ec-edit-variation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Click `}<em parentName="p">{`Edit variation`}</em>{`, to change the `}<em parentName="p">{`Weight on distribution`}</em>{` of the version and activate the `}<em parentName="p">{`Segmentation`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4269ab8b82ca75d9948e558c491d531a/1a2fb/ec-edit-variation-segmentation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA90lEQVQoz62QQUvDQBCF94/7N/wFeldBFA+CSK2aVGxQSFs8mSqNBzUmJunuzCe7aQ6CLSg+eMyyO/vezDMX1zFng0sGVzHD6IZhNPo1vUZ8O2bv8BiDKk1d8x/I8xzTHRVVRUVQFaqqoixL6rrGWsfSWuwaOudC9ciyDCPA0jpEldYKVhQRCY0SDHQjwzirOp/PMUXjKD5bXj4akuyV6aIIBr5JZLOYN/T0W4QJvWBw8JfOdeM7Ceu+VzWtF159+oloF1GPxfNTn+HfYEP6MMoKjqZvJA+PmNlsRpqm3ziZTNayf/c1Gt8xSu7ZPjhna/eUnf0TvgAOH2BRDEtQoQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4269ab8b82ca75d9948e558c491d531a/e93cc/ec-edit-variation-segmentation.webp 300w", "/static/4269ab8b82ca75d9948e558c491d531a/b0544/ec-edit-variation-segmentation.webp 600w", "/static/4269ab8b82ca75d9948e558c491d531a/68fc1/ec-edit-variation-segmentation.webp 1200w", "/static/4269ab8b82ca75d9948e558c491d531a/a2303/ec-edit-variation-segmentation.webp 1800w", "/static/4269ab8b82ca75d9948e558c491d531a/33eb2/ec-edit-variation-segmentation.webp 1840w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4269ab8b82ca75d9948e558c491d531a/eed55/ec-edit-variation-segmentation.png 300w", "/static/4269ab8b82ca75d9948e558c491d531a/7491f/ec-edit-variation-segmentation.png 600w", "/static/4269ab8b82ca75d9948e558c491d531a/8537d/ec-edit-variation-segmentation.png 1200w", "/static/4269ab8b82ca75d9948e558c491d531a/d2cc9/ec-edit-variation-segmentation.png 1800w", "/static/4269ab8b82ca75d9948e558c491d531a/1a2fb/ec-edit-variation-segmentation.png 1840w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4269ab8b82ca75d9948e558c491d531a/8537d/ec-edit-variation-segmentation.png",
              "alt": "ec-edit-variation-segmentation",
              "title": "ec-edit-variation-segmentation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. On the left bottom corner of form `}<em parentName="p">{`Edit variation`}</em>{`, click button `}<em parentName="p">{`Accept`}</em>{`.
4. On `}<em parentName="p">{`Settings`}</em>{` define`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`Page distribution mode`}</em>{`: `}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Sequencial`}</em>{`, `}</li>
          <li parentName="ul"><em parentName="li">{`Random`}</em>{`,`}</li>
          <li parentName="ul"><em parentName="li">{`Random with differents weights`}</em>{` `}</li>
        </ul>
      </li>
      <li parentName="ol"><em parentName="li">{`Test conversion goal`}</em>{`: `}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Pages`}</em>{`, `}</li>
          <li parentName="ul"><em parentName="li">{`Event lists`}</em>{`, `}</li>
          <li parentName="ul"><em parentName="li">{`Contacts`}</em>{`, `}</li>
          <li parentName="ul"><em parentName="li">{`Campaign contacts`}</em>{`, `}</li>
          <li parentName="ul"><em parentName="li">{`Campaign elements accepted`}</em>{`,`}</li>
          <li parentName="ul"><em parentName="li">{`Event (via workflow)`}</em>{` .`}</li>
        </ul>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/61dfceeb7dc2909390fa15cf9eef3f49/d8076/ec-edit-ab-settings.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABxElEQVQoz52Ry2oUQRSG+y1ERLzfwAcQ3OnKd9GNoDgOkcRLyEIQXLgx3jLRCEGmoygadSUmZpKVu2iP100WMzrdPdNd90+qu2dQiC488EOdquKr/68T7D7dYOfEIvvGn7L1/AJbak221ZtsH2uy40KTXeMheyZC9l4K2X855MCVkIOTCxyaDDk89Zgj155x7MYix68/4ejVJsGJqUc0Vr8x/eYDN99GTC9F3FqKuL0cceddm7srbe55tdrMtNo0VkvNrvn+I3NrERefv+fk/Aqn5pcJzjVeUZbjf2sjFqxv9Gl97RCcmXlZbEptsNZhrMVYR5L26SUJmZDFmTIWZcwf0sairR2BP3djgtr91yVQaYQuL1nnUEoVsv4BY8oMzm0qb8BX1OkR1B6UkdNc0kkFmSzdaK3QWo+gf4N52Qr46UePoF4BTWHdoZXCaE2SJAghyLJs5PBfLovIP2OC+lz5h0qbAuoBcZKQpmkB9C6Hsf3abQK1FfCLB449rBwOXThHmiakfiBZhpIS62H+T6Uc3fm9hg6/xwnB2dkXRTMQkrwaTD/PifsD0kFGMsjIpSqmPRACaQxC62Lyharep1jvdvkFd97bV9qEU1YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/61dfceeb7dc2909390fa15cf9eef3f49/e93cc/ec-edit-ab-settings.webp 300w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/b0544/ec-edit-ab-settings.webp 600w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/68fc1/ec-edit-ab-settings.webp 1200w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/a2303/ec-edit-ab-settings.webp 1800w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/4293a/ec-edit-ab-settings.webp 2400w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/aa4b5/ec-edit-ab-settings.webp 2996w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/61dfceeb7dc2909390fa15cf9eef3f49/eed55/ec-edit-ab-settings.png 300w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/7491f/ec-edit-ab-settings.png 600w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/8537d/ec-edit-ab-settings.png 1200w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/d2cc9/ec-edit-ab-settings.png 1800w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/00711/ec-edit-ab-settings.png 2400w", "/static/61dfceeb7dc2909390fa15cf9eef3f49/d8076/ec-edit-ab-settings.png 2996w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/61dfceeb7dc2909390fa15cf9eef3f49/8537d/ec-edit-ab-settings.png",
              "alt": "ec-edit-ab-settings",
              "title": "ec-edit-ab-settings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
5. Activate on `}<em parentName="p">{`Status`}</em>{` the A/B test and on the right top corner of form `}<em parentName="p">{`Edit onsite A/B testing`}</em>{`, click button `}<em parentName="p">{`Save A/B test`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f7ed3b727d916c6433f84c2013f93996/ca32c/ec-save-ab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABKklEQVQoz4WRy04DMQxF8/V8A5/BmjU7VqxYVLxaJCiCdpJMXraTi+KZUmgLWLqKldgnfpjr+zfcrbdYvA54/BjxNGu5CVhuA5abca/tiNWXAh7eLZ43Fhc3K5xd3uL8agGTCuOUNWFUpu7hd5vefGa82IS1SzA+0VFIrRXee8SUQMxgEb07qbb/UGqFGWIBS0UiQSyMwqJBh4n6WWt/KhHDDGEC+kywicC1aUWpV0eEUooC/4OhNWRmmDHvW2Yi5JwV5pxTWIxxStiNZPaPgAAyC4yfgb3NPoMxBFhrFdThIlPLIgJm1hmfrFCBDBN2W54ve5KzFiEEhTITaocRgUr5EXtoJAKzdhGkS2EtucuFCB8TXAjwMSIRIfb2c0ER0cXp+U197kNO+AQR28BLn+LD7AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f7ed3b727d916c6433f84c2013f93996/e93cc/ec-save-ab.webp 300w", "/static/f7ed3b727d916c6433f84c2013f93996/b0544/ec-save-ab.webp 600w", "/static/f7ed3b727d916c6433f84c2013f93996/68fc1/ec-save-ab.webp 1200w", "/static/f7ed3b727d916c6433f84c2013f93996/a2303/ec-save-ab.webp 1800w", "/static/f7ed3b727d916c6433f84c2013f93996/4293a/ec-save-ab.webp 2400w", "/static/f7ed3b727d916c6433f84c2013f93996/b7638/ec-save-ab.webp 2768w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f7ed3b727d916c6433f84c2013f93996/eed55/ec-save-ab.png 300w", "/static/f7ed3b727d916c6433f84c2013f93996/7491f/ec-save-ab.png 600w", "/static/f7ed3b727d916c6433f84c2013f93996/8537d/ec-save-ab.png 1200w", "/static/f7ed3b727d916c6433f84c2013f93996/d2cc9/ec-save-ab.png 1800w", "/static/f7ed3b727d916c6433f84c2013f93996/00711/ec-save-ab.png 2400w", "/static/f7ed3b727d916c6433f84c2013f93996/ca32c/ec-save-ab.png 2768w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f7ed3b727d916c6433f84c2013f93996/8537d/ec-save-ab.png",
              "alt": "ec-save-ab",
              "title": "ec-save-ab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Result`}</h4>
    <p>{`After saving, A/B test is saved.`}</p>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`By default, the AB campaign has two variations, the control one and the variation one (identical). `}</li>
        <li parentName="ul">{`The desired changes should be made in variation first content. `}</li>
        <li parentName="ul">{`It is possible to create a max of four variants. `}</li>
        <li parentName="ul">{`Possibility to define % of contacts that should be used on test.`}</li>
      </ul>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      